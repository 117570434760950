import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  B1Blue,
  colors,
  H1White,
  H2Blue,
  medWrapper,
} from "../../../styles/helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import BackgroundOne from "../../Icons/BackgroundOne"
import smileBlue from "../../../images/smile-blue.png"

const getData = graphql`
  {
    beforeAfters: allWpBeforeAfterSingle {
      edges {
        node {
          title
          slug
          postTypeBeforeAndAfter {
            issueWas
            imageBefore {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const BeforeAfter = ({ data }) => {
  const baaData = useStaticQuery(getData)
  if (!data.beforeDisplay && !baaData.beforeAfters.edges.length > 0) return null

  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="content">
          <h2>Before + Afters</h2>
          <p>{data.beforeContent}</p>
        </div>
        <div className="case-studies">
          {baaData.beforeAfters.edges.map((item, index) => {
            const imageDisplay = getImage(
              item.node.postTypeBeforeAndAfter.imageBefore.localFile
                .childImageSharp.gatsbyImageData
            )
            const imageAlt =
              item.node.postTypeBeforeAndAfter.imageBefore.altText
            return (
              <CaseStudy to={`/before-and-after/${item.node.slug}`} key={index}>
                <div className="case-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                  <div className="case-hover">
                    <div className="case-hover__wrap">
                      <img src={smileBlue} alt="" />
                    </div>
                  </div>
                </div>
                <div className="case-title">
                  <h2>{item.node.title}</h2>
                  <p>{item.node.postTypeBeforeAndAfter.issueWas}</p>
                </div>
              </CaseStudy>
            )
          })}
        </div>
      </div>
      <div className="bg-background">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  padding: 10rem 0;

  .wrapper {
    ${medWrapper};
  }

  .content {
    width: calc(100%);
    margin: 5rem auto;
    text-align: center;

    h2 {
      ${H1White};
      display: inline-block;
      padding: 0 2rem;
      background-color: ${colors.colorPrimary};
    }

    p {
      ${H2Blue};
      margin: 2rem auto;
      max-width: 100rem;
    }
  }

  .case-studies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .bg-background {
    position: absolute;
    max-width: 45rem;
    bottom: -37.5rem;
    right: -20rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      right: -20rem;
      z-index: -1;
    }

    @media (min-width: 1025px) {
      bottom: -20rem;
      right: -20rem;
    }

    @media (min-width: 1200px) {
      bottom: -25rem;
      right: -15rem;
    }
  }
`

const CaseStudy = styled(Link)`
  position: relative;
  width: 100%;
  background-color: #fff;

  @media (min-width: 768px) {
    width: calc(33.3333333% - 2rem);
    margin: 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333333% - 4rem);
    margin: 1rem 2rem;
  }

  .case-image {
    position: relative;
    border-bottom: 2.5rem solid ${colors.colorPrimary};
  }

  .case-title {
    h2 {
      ${H2Blue};
      margin-bottom: 0;
    }

    p {
      ${B1Blue};
      margin-bottom: 0;
    }
  }

  .case-hover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(217, 201, 158, 0.8);
    transform: scaleY(0%);
    transform-origin: bottom center;
    transition: all 0.3s ease-out;
    overflow: hidden;
    z-index: 1000;

    &__wrap {
      width: 12.5rem;
      margin: auto;
      transition: all 0.3s ease-out 0.3s;
      transform: translateX(-250%);
    }
  }

  &:hover {
    .case-hover {
      transform: scaleY(100%);

      &__wrap {
        transform: translateX(0%);
      }
    }
  }
`

export default BeforeAfter
