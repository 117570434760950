import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import BeforeAfter from "../components/templates/before/BeforeAfter"
import ReadyHero from "../components/templates/common/ReadyHero"

const Before = props => {
  const { hero, beforeAfter, readyHero, seoInfo } = props.data
  const heroData = hero.template.templateBeforePage
  const beforeAfterData = beforeAfter.template.templateBeforePage
  const readyHeroData = readyHero.template.templateBeforePage
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <BeforeAfter data={beforeAfterData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const beforeTempQuery = graphql`
  query beforeTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_BeforeAndAfter {
          templateBeforePage {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    beforeAfter: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_BeforeAndAfter {
          templateBeforePage {
            beforeDisplay
            beforeContent
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_BeforeAndAfter {
          templateBeforePage {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Before
