import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import HeroImage from "./HeroImage"

const Hero = ({ data }) => {
  const bgImg = data.heroImage
  const imageDisplay = getImage(
    data.heroLogo.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.heroLogo.altText
  return (
    <StyledSection>
      <HeroImage bgImg={bgImg} />
      <div className="hero-content">
        <div className="hero-content__logo">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40rem;

  @media (min-width: 768px) {
    height: 40rem;
  }

  @media (min-width: 1025px) {
    height: 45rem;
  }

  .hero-content {
    position: relative;
    width: 100%;
    max-width: 75rem;
    z-index: 100;

    &__logo {
      max-width: 30rem;
      margin: auto auto 5rem;

      @media (min-width: 768px) {
        max-width: 100%;
      }
    }
  }
`

export default Hero
